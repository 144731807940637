import { ControllerParams, CreateControllerFn } from '@wix/yoshi-flow-editor';
import { faqAppStartLoading } from '@wix/bi-logger-labs-data/v2';
import { FAQService } from '../../utils/FAQService';
import { Category } from '@wix/ambassador-faq-category-v2-category/types';
import { ISettingsGetter } from '@wix/tpa-settings';
import settingsParams from './settingsParams';
import constants from '../../constants';
import { QuestionEntry } from '@wix/ambassador-faq-question-entry-v2-question-entry/types';

const asyncFun = async (
  settings: { get: any },
  api: FAQService,
  qcategoryId: string | null | undefined,
) => {
  let selectedCategory;
  let questionEntries: QuestionEntry[] | null;
  const { showAll, categories } = settings.get(settingsParams.manageCategories);
  const { categories: categriesList } = await api.getListCategories();

  const oneListOfQuestions =
    settings.get(settingsParams.displayContent) ===
    constants.DISPLAY_CONTENT.ONE_LIST_OF_QUESTIONS;
  let firstId: string | undefined | null = qcategoryId ?? '';
  const validLists = categriesList?.filter((category: { id?: any }) => {
    const categoryId = category?.id;
    if (showAll) {
      if (typeof firstId === 'undefined' || firstId === '') {
        firstId = categoryId;
      }
      return true;
    }
    if (!categoryId) {
      return false;
    }
    const isValid = categories?.includes(categoryId);
    if (isValid) {
      if (typeof firstId === 'undefined' || firstId === '') {
        firstId = categoryId;
      }
      return true;
    }
  });
  const validCategories = validLists
    ?.filter((category: Category) => category?.id)
    .map((category: Category) => category?.id);
  if (oneListOfQuestions) {
    selectedCategory = validCategories?.slice(0, 100);
  } else if (firstId) {
    selectedCategory = firstId;
  }
  const result = await api.getListQuestions({
    categoryId: selectedCategory,
  });
  if (result.questionEntries) {
    questionEntries = result.questionEntries;
  } else {
    questionEntries = [];
  }
  return { selectedCategory, validCategories, questionEntries, validLists };
};
const createController: CreateControllerFn = async ({
  flowAPI,
  controllerConfig,
}: ControllerParams) => {
  function getUuidQuestionIdFromUrl(url: string): string | null {
    const match = url.match(
      /[?&]questionId=([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:&|$)/,
    );
    return match ? match[1] : null;
  }
  function getAppDefIdFromUrl(url: string): string | null {
    const match = url.match(
      /[?&]appDefId=([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})(?:&|$)/i,
    );
    return match ? match[1] : null;
  }

  const getQuestionId = async (api: FAQService) => {
    const appID = controllerConfig.appParams.appDefinitionId;
    const url = controllerConfig.wixCodeApi.location.url;
    const appDefId = getAppDefIdFromUrl(url);
    const isFAQAppDefId = appDefId === appID;
    const questionId = isFAQAppDefId ? getUuidQuestionIdFromUrl(url) : null;
    if (!questionId) {
      return {
        categoryId: null,
        questionId: null,
        fromSearch: false,
      };
    }
    const result = await api.getQuestion({ id: questionId });
    return {
      categoryId: result.questionEntries?.[0].categoryId,
      questionId: result.questionEntries?.[0].id,
      fromSearch: true,
    };
  };

  const getInitialData = async (
    settings: { get: ISettingsGetter },
    faqService: FAQService,
    categoryId: string | null | undefined,
  ) => {
    let result;
    try {
      result = await asyncFun(settings, faqService, categoryId);
    } catch (e) {
      // console.error(e);
      result = {
        validLists: null,
        selectedCategory: null,
        validCategories: null,
        questionEntries: null,
      };
    }
    return result;
  };

  const onPageReady = async () => {
    const { appParams, wixCodeApi } = controllerConfig;
    const { instance, instanceId } = appParams;
    const { url } = wixCodeApi.location;
    const baseURL = url;

    const api = new FAQService(
      instance,
      instanceId,
      wixCodeApi?.window?.multilingual,
    );

    const questionIdResult = await getQuestionId(api);
    let { questionId } = questionIdResult;
    const { categoryId, fromSearch } = questionIdResult;
    const initialData = await getInitialData(flowAPI.settings, api, categoryId);
    if (initialData.validLists?.length !== 0 && !questionId) {
      const showFirstAnswer = flowAPI.settings.get(
        settingsParams.openFirstAnswer,
      );
      if (showFirstAnswer) {
        questionId = initialData.questionEntries?.[0].id;
      }
    }
    const allProps = {
      instance,
      instanceId,
      baseURL,
      questionId,
      initialData,
      fromSearch,
      api: {
        getListCategories: api.getListCategories,
        getListQuestions: api.getListQuestions,
      },
      fitToContentHeight: true,
    };

    controllerConfig.setProps(allProps);
  };
  return {
    async pageReady() {
      const result = await onPageReady();
      const biLogger = flowAPI.bi;
      try {
        biLogger?.report(faqAppStartLoading({}));
      } catch (e) {
        console.warn('biLogger report faqAppStartLoading failed');
      }
      return result;
    },
    async updateAppSettings() {
      return onPageReady();
    },
  };
};

export default createController;
